import axios from './request'

export const getHomeDataApi = () => {
  return axios.request({
    url: '/home/getHomeData',
    method: 'get'
  })
}
export const getStoreDataApi = () => {
  return axios.request({
    url: '/getStoreData',
    method: 'get'
  })
}
